import "./App.css";
import { useState, useEffect } from "react";

// choose random boolean value
function getRandomBoolean() {
  return Math.random() >= 0.5;
}

const heart = (
  <g transform="translate(129.28571,-64.285714) scale(0.1 0.1)">
    <path
      d="M 297.29747,550.86823 C 283.52243,535.43191 249.1268,505.33855 220.86277,483.99412 C 137.11867,420.75228 125.72108,411.5999 91.719238,380.29088 C 29.03471,322.57071 2.413622,264.58086 2.5048478,185.95124 C 2.5493594,147.56739 5.1656152,132.77929 15.914734,110.15398 C 34.151433,71.768267 61.014996,43.244667 95.360052,25.799457 C 119.68545,13.443675 131.6827,7.9542046 172.30448,7.7296236 C 214.79777,7.4947896 223.74311,12.449347 248.73919,26.181459 C 279.1637,42.895777 310.47909,78.617167 316.95242,103.99205 L 320.95052,119.66445 L 330.81015,98.079942 C 386.52632,-23.892986 564.40851,-22.06811 626.31244,101.11153 C 645.95011,140.18758 648.10608,223.6247 630.69256,270.6244 C 607.97729,331.93377 565.31255,378.67493 466.68622,450.30098 C 402.0054,497.27462 328.80148,568.34684 323.70555,578.32901 C 317.79007,589.91654 323.42339,580.14491 297.29747,550.86823 z"
      style={{ fill: "#dd4444" }}
    />
    {/* <g transform="translate(129.28571,-64.285714) scale(0.1 0.1)" /> */}
  </g>
);

function App() {
  // const [order, setOrder] = useState(getRandomBoolean());
  const [order, setOrder] = useState(true);

  const [pos, setPos] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setPos((pos) => pos + 1);
    }, 10);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setOrder((order) => !order);
    }, 10000);
    return () => clearTimeout(timer);
  }, []);
  const string1 = `${order ? "Buck" : "Claire"} and ${
    order ? "Claire" : "Buck"
  }`;

  const loopPos = pos % 140;
  const loopPos2 = (pos + 50) % 160;
  const whichLoop = Math.floor(pos / 140) % 2;
  const whichLoop2 = Math.floor((pos + 50) / 160) % 2;

  const movedHeart = (
    <g>
      <g
        transform={`translate(${390 - loopPos * ((whichLoop - 0.5) * 2)}, ${
          200 - loopPos ** 2 * 0.005 - loopPos * 0.8
        }) `}
      >
        <g transform={" " || `scale(${loopPos / 140 + 1})`}>{heart}</g>{" "}
      </g>
      {pos > 50 && (
        <g transform={" " || `scale(${loopPos2 / 140 + 1})`}>
          <g
            transform={`translate(${
              390 + loopPos2 * ((whichLoop2 - 0.5) * 2)
            }, ${200 - loopPos2 ** 2 * 0.002 - loopPos2 * 0.8})`}
          >
            {heart}
          </g>
        </g>
      )}
    </g>
  );

  // we're using bootstrap.
  // we want to make a page with:
  // - a nav using the bootstrap navbar containing links to other pages on the website

  return (
    <div className="App">
      <ul className="nav justify-content-center nav-pills">
      
        <li className="nav-item my-nav-item">
          <a className="nav-link" aria-current="page" href="/"> Home </a>
        </li>
        <li className="nav-item my-nav-item">
          <a className="nav-link" href="/what-means.html">
            What marriage and weddings mean to us
          </a>
        </li>
 

        <li className="nav-item my-nav-item">
          <a className="nav-link" href="/schedule.html"> Schedule </a>
        </li>
        <li className="nav-item my-nav-item">
          <a className="nav-link" href="/notes.html">Notes on love </a>
        </li>
          <li className="nav-item my-nav-item">
            <a className="nav-link" href="/photos.html">Engagement photos</a>
          </li>
      
      </ul>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="text-center">{string1} are getting married!</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <p>
              <strong>Where:</strong>{" "}
              <a href="https://g.page/TripleSRanchNapa?share">
                Triple S Ranch, Calistoga, CA
              </a>{" "}
              (90 minutes north of the SF Bay Area)
            </p>
            <p>
              <strong>When:</strong> June 9th through 11th, 2023. The ceremony
              is on the 10th.
            </p>
          </div>
          <div className="col-6">
            <div>
              {/* <img
                style={{ transform: order ? "scaleX(-1)" : "" }}
                src="photo.png"
                alt="Buck and Claire"
                width={"100%"}
              /> */}
              <div className="img-overlay-wrap">
                <img
                  style={{ transform: order ? "scaleX(-1)" : "" }}
                  src="photo.png"
                  alt="Buck and Claire"
                  width={"100%"}
                />
                <svg viewBox="0 0 1000 1000">{movedHeart}</svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
